<template>
  <!--begin::Col-->
  <div class="col-md-6 col-xxl-4">
    <!--begin::Card-->
    <div
      class="card position-relative"
      :class="[cardClasses, { 'card-selected': localChecked }]"
    >
      <!--begin::Checkbox-->
      <div class="position-absolute top-0 start-0 m-3">
        <div
          class="
            form-check form-check-sm form-check-custom form-check-solid
            me-3
          "
        >
          <input
            type="checkbox"
            class="form-check-input"
            :id="`checkbox-${name}`"
            v-model="localChecked"
            @change="onCheckChange"
          />
        </div>
      </div>
      <button
        class="
          btn btn-sm btn-icon btn-bg-light btn-active-color-primary
          position-absolute
          top-0
          end-0
          m-3
          z-index-10
        "
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-three-dots fs-3"></i>
      </button>
      <CandidateDropdown
        :candidateId="id"
        :candidateStatus="status"
        :hasInterviewSession="hasInterviewSession"
        :jobPostingId="jobPostingId"
      />
      <!--end::Checkbox-->
      <!--begin::Card body-->
      <div class="card-body d-flex flex-center flex-column p-9">
        <!--begin::Avatar-->
        <div class="symbol symbol-65px symbol-circle mb-5">
          <span
            :class="`text-${color} bg-light-${color}`"
            class="symbol-label fs-2x fw-bold"
            >{{ initials }}</span
          >
          <div
            v-if="online"
            class="
              bg-success
              position-absolute
              rounded-circle
              translate-middle
              start-100
              top-100
              border border-4 border-white
              h-15px
              w-15px
              ms-n3
              mt-n3
            "
          ></div>
        </div>
        <!--end::Avatar-->
        <router-link :to="'/interviewer/candidate-detail/' + id">
          <!--begin::Name-->
          <a class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">{{
            name
          }}</a>
          <!--end::Name-->
        </router-link>
        <!--begin::Position-->
        <div class="fw-bold text-gray-400 mb-4">{{ email }}</div>
        <!--end::Position-->
        <a class="text-center">
          <span v-if="!status" class="badge badge-light-success">
            Aktif Aday
          </span>
          <div v-else>
            <span
              v-if="status === InterviewCandidateStatus.ACTIVE"
              class="badge badge-light-success"
            >
              Aktif Aday
            </span>
            <span
              v-else-if="status === InterviewCandidateStatus.PASSIVE"
              class="badge badge-light-warning"
            >
              Pasif Aday
            </span>
            <span
              v-else-if="status === InterviewCandidateStatus.ELIMINATED"
              class="badge badge-light-danger"
            >
              {{ $t("modals.interview.candidate.eliminated") }}
            </span>
          </div>
        </a>
      </div>
      <!--begin::Card body-->
    </div>
    <!--begin::Card-->
  </div>
  <!--end::Col-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import CandidateDropdown from "@/presentation/components/dropdowns/CandidateDropdown.vue";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";

export default defineComponent({
  name: "candidate-item",
  components: { CandidateDropdown },
  props: {
    id: Number,
    cardClasses: String,
    avatar: String,
    online: Boolean,
    initials: String,
    color: String,
    name: String,
    email: String,
    connected: Boolean,
    modelValue: Boolean,
    status: String,
    hasInterviewSession: Boolean,
    jobPostingId: Number,
  },
  emits: ["update:modelValue"], // Parent'e veri göndermek için
  setup(props, { emit }) {
    const localChecked = ref(props.modelValue);

    const onCheckChange = () => {
      emit("update:modelValue", localChecked.value);
    };

    return { localChecked, onCheckChange, InterviewCandidateStatus };
  },
});
</script>

<style scoped>
.card-selected {
  background-color: #dfedff; /* Seçili durum için açık bir renk */
  border-color: #8a8a8a; /* Seçili durum için vurgu rengi */
}
</style>
