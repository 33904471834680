<template>
  <div
    v-if="isJobPostingLoading"
    style="
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-25px"
        src="/media/loading_icon.gif"
        style="width: 35px"
      />
    </div>
  </div>

  <div v-else class="d-flex flex-column flex-lg-row">
    <!--begin::Sidebar-->

    <div class="flex-lg-row-auto w-100 w-lg-200px w-xl-400px mb-10">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card header-->
        <span
          v-if="jobPosting.status"
          :class="JobPostingStatusBadge(jobPosting.status)"
        >
          {{
            jobPosting.status === JobPostingStatus.ACTIVE
              ? $t("common.active")
              : jobPosting.status === JobPostingStatus.PENDING
              ? $t("common.waiting")
              : jobPosting.status === JobPostingStatus.COMPLETED
              ? $t("common.completed")
              : $t("common.inactive")
          }}
        </span>
        <div class="card-header" style="border-bottom: 0px">
          <div class="row d-flex justify-content-between">
            <div class="card-title">
              <router-link
                :to="'/interviewer/jobposting-detail/' + jobPosting.id"
              >
                <h2 class="mb-0 text-hover-primary mt-5">
                  {{ jobPosting.title }}
                </h2>
              </router-link>
            </div>
          </div>
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-1">
          <!--begin::Permissions-->

          <div class="fw-bold text-gray-600 mb-3">
            {{ formatLocalizedDate(jobPosting.startDate) }} -
            {{ formatLocalizedDate(jobPosting.endDate) }}
          </div>

          <div class="fw-bold text-gray-600 mb-3">
            <a
              data-bs-toggle="modal"
              :data-bs-target="'#descriptionModal' + jobPosting.id"
              class="
                text-primary text-decoration-underline
                fw-bold
                cursor-pointer
              "
            >
              {{ $t("modals.interview.viewDetail") }}
            </a>
          </div>

          <!--begin::Modal-->
          <div
            class="modal fade"
            :id="'descriptionModal' + jobPosting.id"
            tabindex="-1"
            aria-labelledby="descriptionModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="descriptionModalLabel">
                    {{ $t("modals.interview.jobPostingDesc") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body mh-500px overflow-auto">
                  <div v-html="jobPosting.description"></div>

                  <div class="separator my-4"></div>

                  <div
                    v-for="(
                      qualification, i
                    ) in jobPosting.jobPostingQualifications"
                    :key="i"
                    class="d-flex align-items-center py-2"
                  >
                    <span class="bullet bg-primary me-3"></span>
                    {{ qualification.text }}
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("common.button.close") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--end::Modal-->

          <router-link :to="'/interviewer/jobposting-detail/' + jobPosting.id">
            <div class="fw-bold text-gray-600 mb-3">
              {{ $t("modals.interview.candidateCount") }}:
              {{ jobPosting.candidateCount }}
            </div>

            <div class="d-flex flex-column text-gray-600 pb-5">
              <div
                v-for="(qualification, i) in visibleRequirements(
                  jobPosting.jobPostingQualifications
                )"
                :key="i"
                class="d-flex align-items-center py-2"
              >
                <span class="bullet bg-primary me-3"></span>
                {{ qualification.text }}
              </div>

              <div
                v-if="
                  jobPosting.jobPostingQualifications &&
                  jobPosting.jobPostingQualifications.length > 3
                "
                class="d-flex align-items-center py-2"
              >
                <span class="bullet bg-primary me-3"></span>
                <em
                  >{{
                    hiddenRequirementCount(jobPosting.jobPostingQualifications)
                  }}
                  {{ $t("modals.interview.morePieces") }}</em
                >
              </div>
            </div>
          </router-link>
          <!--end::Permissions-->
          <div class="d-flex flex-column flex-md-row justify-content-between">
            <router-link
              :to="'/interviewer/jobposting-detail/' + jobPosting.id"
              class="flex-grow-1"
            >
              <a class="btn btn-sm btn-primary btn-active-secondary w-100">
                {{ $t("common.button.review") }}
              </a>
            </router-link>
          </div>

          <div class="separator my-4" v-if="jobPosting.interviews && jobPosting.interviews.length > 0"></div>

          <div class="d-flex flex-wrap gap-2 mb-3" v-if="jobPosting.interviews && jobPosting.interviews.length > 0">
            <!-- Görünen mülakat öğeleri -->
            <div
              v-for="(item, index) in visibleItems(jobPosting.interviews)"
              :key="index"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
                 @click="goInterviewDetailPage(item.id)"
              style="flex: 0 0 auto; min-width: 150px"
            >
                <span class="fs-6 fw-semibold text-muted lh-1"
                  >{{ item.title }}
                </span>
            </div>

            <!-- +X butonu -->
            <div
              v-if="hiddenItemCount(jobPosting.interviews) > 0 && !showAll"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
              style="flex: 0 0 auto; cursor: pointer; min-width: 55px"
              @click="showAllInterviews"
            >
              <span class="fs-6 fw-semibold text-muted lh-1"
                >+{{ hiddenItemCount(jobPosting.interviews) }}</span
              >
            </div>

            <!-- Gizli mülakatlar gösterildiğinde -->
            <div
              v-if="showAll"
              v-for="(item, index) in hiddenItems(jobPosting.interviews)"
              :key="index"
              class="
                border border-dashed border-gray-300
                rounded
                p-4
                text-center
                interview-item
              "
               @click="goInterviewDetailPage(item.id)"
              style="flex: 0 0 auto; min-width: 150px"
            >
                <span class="fs-6 fw-semibold text-muted lh-1">{{
                  item.title
                }}</span>
            </div>
          </div>
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <!-- <div class="card-footer flex-wrap pt-0">
          <router-link :to="
                '/interviewer/choose-invite-type/' +
                jobPosting.id
              ">
            <a class="btn btn-sm btn-primary btn-active-primary my-1 w-100">
              Aday Davet Et
            </a>
          </router-link>
        </div> -->

        <!--end::Card footer-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Sidebar-->
    <div
      class="flex-lg-row-fluid ms-lg-10 mt-5"
      v-if="isCandidatesLoading"
      style="
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="text-center px-5 pb-7">
        <img
          alt="Logo"
          class="max-h-25px"
          src="/media/loading_icon.gif"
          style="width: 35px"
        />
      </div>
    </div>
    <!--begin::Content-->
    <div class="flex-lg-row-fluid ms-lg-10" v-else>
      <div v-if="candidateList.length > 0 && !isCandidatesLoading">
        <div class="d-flex flex-wrap flex-stack mb-4">
          <!--begin::Title-->
          <h2 class="d-flex align-items-center">
            {{ $t("modals.interview.candidate.jobPostingCandidateList") }}
            <span class="text-gray-600 fs-6 ms-1"
              >({{ jobPosting.candidateCount }})</span
            >
          </h2>
          <!--end::Title-->

          <!--begin::Controls-->
          <div class="d-flex my-2">
            <!--begin::Select-->
            <!-- <select
            name="status"
            data-control="select2"
            data-hide-search="true"
            class="form-select form-select-white form-select-sm w-125px"
          >
            <option value="Active" selected>Active</option>
            <option value="Approved">In Progress</option>
            <option value="Declined">To Do</option>
            <option value="In Progress">Completed</option>
          </select> -->

            <div
              class="d-flex justify-content-end align-items-center"
              v-if="
                candidateList.filter((candidate) => candidate.candidate.checked)
                  .length > 0
              "
            >
              <div class="fw-bolder me-5">
                <span class="me-1">{{
                  candidateList.filter(
                    (candidate) => candidate.candidate.checked
                  ).length
                }}</span>
                {{ $t("modals.interview.candidate.candidateSelected") }}
              </div>
              <button class="btn btn-sm btn-danger" @click="deleteCandidate()">
                {{ $t("modals.interview.candidate.deleteCandidates") }}
              </button>
            </div>

            <router-link
              v-else
              :to="'/interviewer/choose-invite-type/' + jobPosting.id"
            >
              <a class="btn btn-sm btn-primary btn-active-primary">
                {{ $t("modals.interview.candidate.inviteCandidate") }}
              </a>
            </router-link>

            <button
              class="btn btn-sm btn-secondary ms-3"
              id="listViewBtn"
              @click="toggleView('list')"
            >
              <i class="fa fa-list"></i>
            </button>
            <button
              class="btn btn-sm btn-secondary ms-3"
              id="cardViewBtn"
              @click="toggleView('card')"
            >
              <i class="fa fa-th-large"></i>
            </button>
            <!--end::Select-->
          </div>
          <!--end::Controls-->
        </div>
        <!--end::Followers toolbar-->

        <!--begin::Row-->
        <div
          class="row g-6 mb-6 g-xl-9 mb-xl-9"
          v-if="viewMode === 'card-view'"
        >
          <CandidateItem
            v-for="(candidate, index) in candidateList"
            :key="index"
            :id="candidate.candidate.candidateId"
            :name="candidate.candidate.fullName"
            :email="candidate.candidate.email"
            :initials="
              candidate.candidate.firstName
                ? candidate.candidate.firstName.charAt(0)
                : ''
            "
            v-model="candidate.candidate.checked"
            :status="candidate.candidate.candidateStatus"
            :hasInterviewSession="candidate.candidate.hasInterviewSession"
            :jobpostingId="jobPosting.id"
          />

          <!-- :color="candidate.color"
        :connected="candidate.connected" 
                  :status="candidate.status"-->

          <!--begin::Pagination-->
          <div
            class="d-flex flex-stack flex-wrap pt-10"
            v-if="candidateList.length != 0"
          >
            <!--begin::Controls-->
            <div class="d-flex flex-wrap my-1">
              <!--begin::Select wrapper-->
              <div class="m-0">
                <!--begin::Select-->
                <select
                  name="status"
                  data-control="select2"
                  data-hide-search="true"
                  class="
                    form-select form-select-white form-select-sm
                    fw-bolder
                    w-125px
                  "
                  v-model="candidatePageSize"
                  @change="getCandidateList(1)"
                >
                  <option value="5">5</option>
                  <option value="10" selected>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span>{{ $t("common.table.pagination.desc2") }}</span>
                <!-- Sayfa başı öğe sayısı -->

                <!--end::Select-->
              </div>
              <!--end::Select wrapper-->
            </div>
            <!--end::Controls-->

            <div class="fs-6 fw-bold text-gray-700">
              {{ candidateTotalItems }}
              {{ $t("common.table.pagination.desc") }}
              <!-- sonuç arasından -->
              {{ (candidateCurrentPage - 1) * candidatePageSize + 1 }} -
              {{
                Math.min(
                  candidateCurrentPage * candidatePageSize,
                  candidateTotalItems
                )
              }}
              {{ $t("common.table.pagination.desc1") }}
              <!-- sonuç gösteriliyor. -->
            </div>

            <!--begin::Pages-->
            <ul class="pagination">
              <li
                class="page-item previous"
                @click="getCandidateList(candidateCurrentPage - 1)"
                :class="{ disabled: candidateCurrentPage === 1 }"
              >
                <a href="#" class="page-link"><i class="previous"></i></a>
              </li>

              <li
                v-for="page in candidatePageCount"
                :key="page"
                class="page-item"
                :class="{ active: candidateCurrentPage === page }"
              >
                <a href="#" class="page-link" @click="getCandidateList(page)">{{
                  page
                }}</a>
              </li>

              <li
                class="page-item next"
                @click="getCandidateList(candidateCurrentPage + 1)"
                :class="{
                  disabled: candidateCurrentPage === candidatePageCount,
                }"
              >
                <a href="#" class="page-link"><i class="next"></i></a>
              </li>
            </ul>
            <!--end::Pages-->
          </div>
          <!--end::Pagination-->
        </div>
        <!--begin::Card-->
        <div
          class="card card-flush mb-6 mb-xl-9"
          v-if="viewMode === 'list-view'"
        >
          <!--begin::Card body-->
          <div class="card-body pt-0">
            <!--begin::Table-->
            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
              <table
                class="table align-middle fs-6 gy-5 mb-0"
                id="kt_roles_view_table"
                style="width: 100%"
              >
                <thead>
                  <tr
                    class="
                      text-gray-400
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                      text-center
                    "
                  >
                    <th>
                      <span class="dt-column-title">
                        <div
                          class="
                            form-check
                            form-check-sm
                            form-check-custom
                            form-check-solid
                            me-3
                          "
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="isAllCandidateChecked"
                            @change="handleCheckChange"
                            data-kt-check="true"
                            data-kt-check-target="#kt_roles_view_table .form-check-input"
                            value="1"
                          />
                        </div>
                      </span>
                      <span class="dt-column-order"></span>
                    </th>
                    <th width="38%">
                      <span class="dt-column-title" role="button">{{
                        $t("modals.interview.candidate.candidateInfo")
                      }}</span>
                      <span class="dt-column-order"></span>
                    </th>
                    <th width="17%">
                      <span class="dt-column-title" role="button">{{
                        $t("modals.interview.candidate.candidateStatus")
                      }}</span>
                      <span class="dt-column-order"></span>
                    </th>
                    <th width="20%">
                      <span class="dt-column-title" role="button">{{
                        $t("modals.interview.candidate.positionEligibility")
                      }}</span>
                      <span class="dt-column-order"></span>
                    </th>

                    <th width="15%">
                      <span class="dt-column-title">{{
                        $t("common.table.process")
                      }}</span>
                      <span class="dt-column-order"></span>
                    </th>
                  </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                  <tr v-for="(candidate, index) in candidateList" :key="index">
                    <td>
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          :id="`checkbox-${candidate.candidate.candidateId}`"
                          v-model="candidate.candidate.checked"
                          @change="onCheckChange"
                        />
                      </div>
                    </td>
                    <td class="d-flex align-items-center">
                      <!-- Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-3
                        "
                      >
                        <span
                          :class="
                            InterviewCandidateStatusClass(
                              candidate.candidate.candidateStatus
                            )
                          "
                          class="symbol-label fs-4 fw-bold"
                          >{{
                            candidate.candidate.firstName
                              ? candidate.candidate.firstName.charAt(0)
                              : ""
                          }}</span
                        >
                      </div>
                      <!-- User details -->
                      <router-link
                        :to="
                          '/interviewer/candidate-detail/' +
                          candidate.candidate.candidateId
                        "
                      >
                        <div class="d-flex flex-column">
                          <a class="text-gray-800 text-hover-primary mb-1">{{
                            candidate.candidate.fullName
                          }}</a>
                          <span class="text-gray-700">{{
                            candidate.candidate.email
                          }}</span>
                        </div>
                      </router-link>
                    </td>
                    <td class="text-center">
                      <span
                        v-if="!candidate.candidate.candidateStatus"
                        class="badge badge-light-success"
                      >
                        {{ $t("modals.interview.candidate.activeCandidate") }}
                      </span>
                      <div v-else>
                        <span
                          v-if="
                            candidate.candidate.candidateStatus ===
                            InterviewCandidateStatus.ACTIVE
                          "
                          class="badge badge-light-success"
                        >
                          {{ $t("modals.interview.candidate.activeCandidate") }}
                        </span>
                        <span
                          v-else-if="
                            candidate.candidate.candidateStatus ===
                            InterviewCandidateStatus.PASSIVE
                          "
                          class="badge badge-light-warning"
                        >
                          {{
                            $t("modals.interview.candidate.deActiveCandidate")
                          }}
                        </span>
                        <span
                          v-else-if="
                            candidate.candidate.candidateStatus ===
                            InterviewCandidateStatus.ELIMINATED
                          "
                          class="badge badge-light-danger"
                        >
                          {{ $t("modals.interview.candidate.eliminated") }}
                        </span>
                      </div>
                    </td>
                    <td class="text-center">
                      <!--begin::Puan (Puan Durumu)-->
                      <div
                        style="display: table-cell; width: 20%"
                        v-if="candidate.candidate.positionSuitability"
                      >
                        <span class="badge badge-light-primary"
                          >{{ candidate.candidate.positionSuitability }}%</span
                        >
                      </div>
                      <div v-else>
                        <span class="badge badge-secondary">
                          {{
                            $t(
                              "modals.interview.candidate.noPositionSuitability"
                            )
                          }}
                        </span>
                      </div>
                      <!--end::Puan-->
                    </td>
                    <td class="text-center">
                      <button
                        class="
                          btn
                          btn-sm
                          btn-icon
                          btn-bg-light
                          btn-active-color-primary
                        "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="bi bi-three-dots fs-3"></i>
                      </button>
                      <CandidateDropdown
                        :candidateId="candidate.candidate.candidateId"
                        :candidateStatus="candidate.candidate.candidateStatus"
                        :hasInterviewSession="
                          candidate.candidate.hasInterviewSession
                        "
                        :jobPostingId="jobPosting.id"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <!--begin::Pagination-->
              <div
                class="d-flex flex-stack flex-wrap pt-10"
                v-if="candidateList.length != 0"
              >
                <!--begin::Controls-->
                <div class="d-flex flex-wrap my-1">
                  <!--begin::Select wrapper-->
                  <div class="m-0">
                    <!--begin::Select-->
                    <select
                      name="candidateStatus"
                      data-control="select2"
                      data-hide-search="true"
                      class="
                        form-select form-select-white form-select-sm
                        fw-bolder
                        w-125px
                      "
                      v-model="candidatePageSize"
                      @change="getCandidateList(1)"
                    >
                      <option value="5">5</option>
                      <option value="10" selected>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                    <span>{{ $t("common.table.pagination.desc2") }}</span>
                    <!-- Sayfa başı öğe sayısı -->

                    <!--end::Select-->
                  </div>
                  <!--end::Select wrapper-->
                </div>
                <!--end::Controls-->

                <div class="fs-6 fw-bold text-gray-700">
                  {{ candidateTotalItems }}
                  {{ $t("common.table.pagination.desc") }}
                  <!-- sonuç arasından -->
                  {{ (candidateCurrentPage - 1) * candidatePageSize + 1 }} -
                  {{
                    Math.min(
                      candidateCurrentPage * candidatePageSize,
                      candidateTotalItems
                    )
                  }}
                  {{ $t("common.table.pagination.desc1") }}
                  <!-- sonuç gösteriliyor. -->
                </div>

                <!--begin::Pages-->
                <ul class="pagination">
                  <li
                    class="page-item previous"
                    @click="getCandidateList(candidateCurrentPage - 1)"
                    :class="{ disabled: candidateCurrentPage === 1 }"
                  >
                    <a href="#" class="page-link"><i class="previous"></i></a>
                  </li>

                  <li
                    v-for="page in candidatePageCount"
                    :key="page"
                    class="page-item"
                    :class="{ active: candidateCurrentPage === page }"
                  >
                    <a
                      href="#"
                      class="page-link"
                      @click="getCandidateList(page)"
                      >{{ page }}</a
                    >
                  </li>

                  <li
                    class="page-item next"
                    @click="getCandidateList(candidateCurrentPage + 1)"
                    :class="{
                      disabled: candidateCurrentPage === candidatePageCount,
                    }"
                  >
                    <a href="#" class="page-link"><i class="next"></i></a>
                  </li>
                </ul>
                <!--end::Pages-->
              </div>
              <!--end::Pagination-->
            </div>
            <!--end::Table-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
      </div>
      <div
        v-else-if="candidateList.length == 0 && !isCandidatesLoading"
        class="card text-center"
      >
        <!-- Card Header (Opsiyonel) -->
        <div class="card-header">
          <h3 class="card-title fw-bold">
            {{ $t("modals.interview.candidate.jobPostingCandidateList") }}
          </h3>
        </div>

        <!-- Card Body -->
        <div class="card-body">
          <p class="fs-7 fw-bold mb-4">
            {{ $t("modals.interview.candidate.zeroCount") }}
          </p>

          <!-- Buton -->
          <router-link :to="'/interviewer/choose-invite-type/' + jobPosting.id">
            <button class="btn btn-sm btn-primary btn-active-primary">
              {{ $t("modals.interview.candidate.inviteCandidate") }}
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import CandidateItem from "@/presentation/components/list-items/CandidateItem.vue";
import CandidateDropdown from "@/presentation/components/dropdowns/CandidateDropdown.vue";
import { DeleteJobPostingModel } from "@/domain/interviewer/delete-job-posting/model/DeleteJobPostingModel";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import { CandidateListModel } from "@/domain/interviewer/candidate-list/model/CandidateListModel";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import {
  InterviewCandidateStatusClass,
  JobPostingStatusBadge,
} from "@/presentation/helper/StatusBadge";
import {
  dateToIsoDate,
  formatLocalizedDate,
} from "@/presentation/helper/DateFormatter";
import { watch } from "vue";

export default defineComponent({
  name: "CandidateList",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
    CandidateItem,
    CandidateDropdown,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const startDate = ref("");
    const endDate = ref("");

    const isJobPostingLoading = ref<boolean>(false);
    const isCandidatesLoading = ref<boolean>(false);
    const viewMode = ref("list-view");
    const url = ref("");
    const positionDesc = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);
    const swalNotification = new SwalNotification();
    const isAllCandidateChecked = ref(false);
    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const jobPostingId = ref<any>(Number(route.params["id"]));
    const jobPosting = ref<JobPostingDetailModel>({});

    const candidateList = ref<CandidateListModel[]>([]);
    const originalCandidateList = ref<CandidateListModel[]>([]);

    const candidateCurrentPage = ref(1);
    const candidatePageSize = ref(10);
    const candidateTotalItems = ref(0);
    const candidatePageCount = ref(1);

    const getJobPosting = () => {
      isJobPostingLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      isJobPostingLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
            getCandidateList(1);
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isJobPostingLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateList = (page) => {
      candidateList.value = [];
      originalCandidateList.value = [];

      isCandidatesLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > candidatePageCount.value) {
        page = candidatePageCount.value;
      }
      candidateCurrentPage.value = page;

      const candidateListModel: CandidateListModel = {
        candidate: {
          jobPostingId: jobPostingId.value,
        },
        page: page,
        pageSize: candidatePageSize.value,
      };

      isCandidatesLoading.value = true;

      interviewerController
        .candidateList(candidateListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((candidateItem) => {
              candidateTotalItems.value = candidateItem.pagination.total;
              candidateCurrentPage.value = candidateItem.pagination.page;
              candidatePageCount.value = candidateItem.pagination.pageCount;
              candidateList.value.push(candidateItem);
              originalCandidateList.value.push(candidateItem);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isCandidatesLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const selectAll = () => {
      candidateList.value.forEach(
        (candidate) => (candidate.candidate.checked = true)
      );
    };

    const deselectAll = () => {
      candidateList.value.forEach(
        (candidate) => (candidate.candidate.checked = false)
      );
    };

    const deleteCandidate = (jobPostingId?: number) => {
      const jobPostingDeleteModel: DeleteJobPostingModel = {
        id: jobPostingId,
      };

      Swal.fire({
        title: t("swalMessages.DELETE_CANDIDATE"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.delete"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        // Swal.fire({
        //   title: t("swalMessages.SUCCESSFUL"),
        //   text: "Aday başarıyla silindi!",
        //   icon: "success",
        //   heightAuto: false,
        // }).then(() => {
        //   window.location.reload();
        // });

        if (result.isConfirmed) {
          Swal.fire({
            title: t("swalMessages.FAILED"),
            text: t("swalMessages.NOT_ACTIVE_YET"),
            icon: "error",
            heightAuto: false,
          });

          // interviewerController
          //   .deleteCandidate(jobPostingDeleteModel)
          //   .then((response) => {
          //     if (response.isSuccess) {
          //     } else {
          //       swalNotification.error(
          //         response.error.cause.cause +
          //           t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
          //           response.error.code +
          //           response.error.cause.code,
          //         t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          //       );
          //     }
          //   })
          //   .catch((e) => {
          //     swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          //   });
        }
      });
    };

    const handleCheckChange = () => {
      if (isAllCandidateChecked.value) {
        selectAll(); // Eğer işaretliyse `selectAll` çağrılır
      } else {
        deselectAll(); // Değilse `deselectAll` çağrılır
      }
    };

    const submitSelected = () => {
      const selectedCandidates = candidateList.value.filter(
        (candidate) => candidate.candidate.checked
      );
    };

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate ? dateToIsoDate(new Date(oldDate)) : "";
      selectedInterviewId.value = id;
    };

    const toggleView = (view) => {
      if (view === "list") {
        viewMode.value = "list-view";
      } else if (view === "card") {
        viewMode.value = "card-view";
      }
    };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(0, maxVisible);
    };
    const hiddenItemCount = (interviews: JobPostingInterviewModel[]) => {
      return Math.max(0, interviews.length - maxVisible); // Negatif sayıyı önlemek için
    };

    const hiddenItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(maxVisible);
    };

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    watch(
      () => store.getters.changedAnyCandidate,
      (newValue) => {
        if (newValue !== null && newValue != 0 && newValue !== undefined) {
          getCandidateList(1);
        }
      },
      { immediate: true }
    );

    const goInterviewDetailPage = (interviewId?: number) => {
      if (interviewId) {
        router.push({ path: `/interviewer/interview-detail/` + interviewId });
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
    });

    return {
      JobPostingStatusBadge,
      InterviewCandidateStatusClass,
      formatLocalizedDate,
      JobPostingStatus,
      InterviewCandidateStatus,
      url,
      form,
      newEndDate,
      changeDateRef,
      selectInterview,
      candidateCurrentPage,
      candidatePageCount,
      candidatePageSize,
      isCandidatesLoading,
      isJobPostingLoading,
      candidateTotalItems,
      positionDesc,
      selectAll,
      submitSelected,
      handleCheckChange,
      isAllCandidateChecked,
      viewMode,
      toggleView,
      deleteCandidate,
      jobPosting,
      visibleRequirements,
      hiddenRequirementCount,
      visibleItems,
      hiddenItemCount,
      hiddenItems,
      showAllInterviews,
      showAll,
      getCandidateList,
      candidateList,
      originalCandidateList,
      goInterviewDetailPage
    };
  },
});
</script>

<style>
.d-flex.align-items-center.py-2 {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.card-title h2 {
  word-wrap: break-word;
  overflow-wrap: anywhere;
  white-space: normal;
  max-width: 100%;
}</style>
