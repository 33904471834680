
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { VueEditor } from "vue3-editor";
import { DeleteCandidateModel } from "@/domain/interviewer/delete-candidate/model/DeleteCandidateModel";
import { UpdateCandidateModel } from "@/domain/interviewer/update-candidate/model/UpdateCandidateModel";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";
import { dateToIsoDate } from "@/presentation/helper/DateFormatter";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "candidate-dropdown",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  props: {
    candidateId: Number,
    candidateStatus: String,
    hasInterviewSession: Boolean,
    jobPostingId: Number,
  },
  setup(props) {
    const today = dateToIsoDate(new Date());
    const startDate = ref(today);
    const router = useRouter();
    const endDate = ref("");
    const featureInput = ref("");
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();
    const { t } = useI18n();

    const updateCandidateModel = ref<UpdateCandidateModel>({
      id: props.candidateId,
      candidateStatus: props.candidateStatus,
    });

    const validateFile = (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          swalNotification.error(
            t("validators_error.max2MBFileByte"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          return;
        }

        // Dosya türü kontrolü (PDF ve DOCX)
        const validTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (!validTypes.includes(file.type)) {
          swalNotification.error(
            t("validators_error.onlyPDFandDOCX"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          return;
        }
      }
    };

    const form = Yup.object().shape({
      candidateStatus: Yup.string()
        .test(
          "is-not-empty",
          t("validators_error.required"), // Hata mesajı
          (value) => value !== "" // Boş string olmadığından emin ol
        )
        .required(t("validators_error.required")) // Hala required kontrolü geçerli
        .label("candidateStatus"),
    });

    // Silme işlemi
    const deleteCandidate = (candidateId?: number) => {
      const candidateDeleteModel: DeleteCandidateModel = {
        id: candidateId,
      };

      Swal.fire({
        title: t("swalMessages.DELETE_CANDIDATE"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.delete"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteCandidate(candidateDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_CANDIDATE),
                  icon: "success",
                  heightAuto: false,
                }).then(() => {
                  store.dispatch(
                    "changedAnyCandidate",
                    store.getters.changedAnyCandidate + 1
                  );
                  router.push({
                    path: "/interviewer/candidate-list/" + props.jobPostingId,
                  });
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updateCandidate = () => {
      // if (!updateCandidateModel.value.cv) {
      //   swalNotification.error(
      //     t("validators_error.requiredCV"),
      //     t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      //   );
      // }
      // else{
      interviewerController
        .updateCandidate(updateCandidateModel.value)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_CANDIDATE),
              icon: "success",
              heightAuto: false,
            }).then(() => {
              store.dispatch(
                "changedAnyCandidate",
                store.getters.changedAnyCandidate + 1
              );
              //burası düzeltilmeli.
              document.querySelectorAll(".modal").forEach((modal) => {
                modal.classList.remove("show");
              });
              document
                .querySelectorAll(".modal-backdrop")
                .forEach((backdrop) => {
                  backdrop.remove();
                });
              document.body.classList.remove("modal-open");
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
      // }
    };

    onMounted(() => {});

    return {
      InterviewCandidateStatus,
      today,
      form,
      startDate,
      endDate,
      deleteCandidate,
      updateCandidate,
      featureInput,
      validateFile,
      updateCandidateModel,
    };
  },
});
