<template>
  <div
    class="
      dropdown-menu
      menu
      menu-sub
      menu-sub-dropdown
      menu-column
      menu-rounded
      menu-gray-800
      menu-state-bg-light-primary
      fw-bold
      py-4
      w-250px
      fs-6
    "
    id="candidateDropdown"
  >
    <!-- <div class="menu-item px-5 my-1">
      <a href="/interviewer/candidate-detail" class="menu-link px-5">
        <routing-link
          :to="'/interviewer/candidate-detail' + candidateId">
        Görüntüle</routing-link>
      </a>
    </div> -->

    <!--begin::Menu item-->
    <div class="menu-item px-5 my-1">
      <a
        href="#"
        data-bs-toggle="modal"
        :data-bs-target="'#kt_modal_update_candidate' + candidateId"
        class="menu-link px-5"
      >
        {{ $t("common.button.edit") }}
      </a>
    </div>
    <!--end::Menu item-->

    <div class="menu-item px-5 my-1">
      <a
        v-if="hasInterviewSession == false"
        href="#"
        @click="deleteCandidate(candidateId)"
        class="menu-link text-danger px-5"
      >
        {{ $t("modals.interview.candidate.deleteCandidate") }}
      </a>
    </div>
  </div>

  <!--begin::Modal-->
  <div
    class="modal fade"
    :id="'kt_modal_update_candidate' + candidateId"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-900px mh-900px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2 class="fw-bold">{{ $t("modals.interview.editCandidate") }}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-kt-roles-modal-action="close"
          >
            <i class="ki-duotone ki-cross fs-1"
              ><span class="path1"></span><span class="path2"></span
            ></i>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 my-7">
          <!--begin::Tabs-->
          <Form
            id="kt_modal_update_role_form"
            class="form fv-plugins-bootstrap5 fv-plugins-framework"
            @submit="updateCandidate"
            :validation-schema="form"
          >
            <!--begin::Scroll-->
            <div
              class="d-flex flex-column scroll-y me-n7 pe-7"
              :id="'kt_modal_update_role_scroll' + candidateId"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_update_role_header"
              data-kt-scroll-wrappers="#kt_modal_update_role_scroll"
              data-kt-scroll-offset="300px"
              style="max-height: 541px"
            >
              <!--begin::Input group-->
              <div class="fv-row mb-10 fv-plugins-icon-container">
                <!--begin::Label-->
                <label class="d-flex form-label mb-5">{{
                  $t("modals.interview.candidate.uploadCV")
                }}</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  type="file"
                  name="cv"
                  v-model="updateCandidateModel.cv"
                  accept=".pdf, .doc, .docx"
                  class="form-control form-control-lg form-control-solid"
                  @change="validateFile"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="cv" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->

              <div class="row mt-4">
                <div class="fv-row">
                  <label class="d-flex form-label mb-5">{{
                    $t("modals.interview.candidate.candidateStatus")
                  }}</label>
                  <Field
                    name="candidateStatus"
                    as="select"
                    v-model="updateCandidateModel.candidateStatus"
                    class="form-select form-select-lg form-select-solid"
                    required
                  >
                    <option value="" disabled selected>
                      {{ $t("common.pleaseChoose") }}
                    </option>
                    <option :value="InterviewCandidateStatus.ACTIVE">
                      {{ $t("modals.interview.candidate.activeCandidate") }}
                    </option>
                    <option :value="InterviewCandidateStatus.PASSIVE">
                      {{ $t("modals.interview.candidate.deActiveCandidate") }}
                    </option>
                    <option :value="InterviewCandidateStatus.ELIMINATED">
                      {{ $t("modals.interview.candidate.eliminatedCandidate") }}
                    </option>
                  </Field>
                  <div
                    class="
                      fv-plugins-message-container
                      d-flex
                      align-items-start
                      pt-2
                    "
                  >
                    <div class="fv-help-block">
                      <ErrorMessage name="candidateStatus" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Scroll-->

            <!--begin::Actions-->
            <div class="text-center pt-15">
              <button
                type="reset"
                class="btn btn-light me-3"
                data-bs-dismiss="modal"
              >
                {{ $t("common.button.cancel") }}
              </button>

              <button
                type="submit"
                class="btn btn-primary"
                data-kt-roles-modal-action="submit"
              >
                <span class="indicator-label">
                  {{ $t("common.button.update") }}
                </span>
                <span class="indicator-progress">
                  {{ $t("common.loading") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal-->
</template>

<script lang="ts">
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { VueEditor } from "vue3-editor";
import { DeleteCandidateModel } from "@/domain/interviewer/delete-candidate/model/DeleteCandidateModel";
import { UpdateCandidateModel } from "@/domain/interviewer/update-candidate/model/UpdateCandidateModel";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";
import { dateToIsoDate } from "@/presentation/helper/DateFormatter";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "candidate-dropdown",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  props: {
    candidateId: Number,
    candidateStatus: String,
    hasInterviewSession: Boolean,
    jobPostingId: Number,
  },
  setup(props) {
    const today = dateToIsoDate(new Date());
    const startDate = ref(today);
    const router = useRouter();
    const endDate = ref("");
    const featureInput = ref("");
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();
    const { t } = useI18n();

    const updateCandidateModel = ref<UpdateCandidateModel>({
      id: props.candidateId,
      candidateStatus: props.candidateStatus,
    });

    const validateFile = (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          swalNotification.error(
            t("validators_error.max2MBFileByte"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          return;
        }

        // Dosya türü kontrolü (PDF ve DOCX)
        const validTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (!validTypes.includes(file.type)) {
          swalNotification.error(
            t("validators_error.onlyPDFandDOCX"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          event.target.value = "";
          return;
        }
      }
    };

    const form = Yup.object().shape({
      candidateStatus: Yup.string()
        .test(
          "is-not-empty",
          t("validators_error.required"), // Hata mesajı
          (value) => value !== "" // Boş string olmadığından emin ol
        )
        .required(t("validators_error.required")) // Hala required kontrolü geçerli
        .label("candidateStatus"),
    });

    // Silme işlemi
    const deleteCandidate = (candidateId?: number) => {
      const candidateDeleteModel: DeleteCandidateModel = {
        id: candidateId,
      };

      Swal.fire({
        title: t("swalMessages.DELETE_CANDIDATE"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.delete"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteCandidate(candidateDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_CANDIDATE),
                  icon: "success",
                  heightAuto: false,
                }).then(() => {
                  store.dispatch(
                    "changedAnyCandidate",
                    store.getters.changedAnyCandidate + 1
                  );
                  router.push({
                    path: "/interviewer/candidate-list/" + props.jobPostingId,
                  });
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updateCandidate = () => {
      // if (!updateCandidateModel.value.cv) {
      //   swalNotification.error(
      //     t("validators_error.requiredCV"),
      //     t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      //   );
      // }
      // else{
      interviewerController
        .updateCandidate(updateCandidateModel.value)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              title: t("swalMessages.SUCCESSFUL"),
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_CANDIDATE),
              icon: "success",
              heightAuto: false,
            }).then(() => {
              store.dispatch(
                "changedAnyCandidate",
                store.getters.changedAnyCandidate + 1
              );
              //burası düzeltilmeli.
              document.querySelectorAll(".modal").forEach((modal) => {
                modal.classList.remove("show");
              });
              document
                .querySelectorAll(".modal-backdrop")
                .forEach((backdrop) => {
                  backdrop.remove();
                });
              document.body.classList.remove("modal-open");
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
      // }
    };

    onMounted(() => {});

    return {
      InterviewCandidateStatus,
      today,
      form,
      startDate,
      endDate,
      deleteCandidate,
      updateCandidate,
      featureInput,
      validateFile,
      updateCandidateModel,
    };
  },
});
</script>
