
import { defineComponent, ref } from "vue";
import CandidateDropdown from "@/presentation/components/dropdowns/CandidateDropdown.vue";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";

export default defineComponent({
  name: "candidate-item",
  components: { CandidateDropdown },
  props: {
    id: Number,
    cardClasses: String,
    avatar: String,
    online: Boolean,
    initials: String,
    color: String,
    name: String,
    email: String,
    connected: Boolean,
    modelValue: Boolean,
    status: String,
    hasInterviewSession: Boolean,
    jobPostingId: Number,
  },
  emits: ["update:modelValue"], // Parent'e veri göndermek için
  setup(props, { emit }) {
    const localChecked = ref(props.modelValue);

    const onCheckChange = () => {
      emit("update:modelValue", localChecked.value);
    };

    return { localChecked, onCheckChange, InterviewCandidateStatus };
  },
});
